import React from 'react';
import instagramLogo from './images/glyph-logo_May2016.png';
import facebookLogo from './images/f_logo_RGB-White_250.png';
import buddha from './images/buddha.png';
import logo from './images/logo_new.png';

const UnderConstruction = () => (
    <div className="page" style={{ backgroundColor: "#7CAE93", width: "100%", margin: 0, fontFamily: "montserrat" }}>
        <img style={{ width: "15%", filter: "brightness(0)",  padding: "20px" }}  src={logo} alt="logo"/>
        <div style={{ backgroundColor: "white", margin: "0", padding: "20px" }}>
            <h2 style={{ paddingTop: "20px" }}>Under Construction</h2>
            <p style={{ fontSize: "1.4rem" }}>We are updating the website, you can contact us on social media, send an email or call to get in touch</p>
            <img style={{ width: "25%" }} src={buddha} alt="under construction"/>
        </div>
        <div align='center' style={{ padding: "20px"}}>
            <a style={{width: "100%", color: "white", fontSize: "1.4rem", fontWeight: 'lighter' }} href="mailto:info@balanceandbeauty.org.uk">info@balanceandbeauty.org.uk</a> 
            <p style={{color: "white", fontSize: "1.25rem", fontWeight: 'lighter', width: "100%" }}>07984914558</p>
            <a style={{width: "100%"}} href='https://facebook.com/balanceandbeautyspennymoor'><img className='social-media-footer' src={facebookLogo} alt="fblogo"/></a>
            <a style={{width: "100%", filter: "invert(100%)"}}href='https://instagram.com/_balance_and_beauty_'><img className='social-media-footer' src={instagramLogo} alt="instalogo"/></a>
        </div>
    </div>
);

export default UnderConstruction;