import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
// import { BrowserRouter, Switch, Route } from 'react-router-dom';
// import Home from './home.jsx';
// import NavBar from './navbar.jsx';
// import Footer from './footer.jsx';
// import Reiki from './reiki.jsx';
// import Massage from './massage.jsx';
// import Contact from './contact.jsx';
// import LashBrow from './lashBrow.jsx';
// import Nails from './nails.jsx';
// import Facials from './facials.jsx';
// import Prices from './prices.jsx';
// import Offers from './offers.jsx';
import UnderConstruction from './underConstruction';
import './App.css';



class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <section>
          <div className="App">
            {/* <NavBar />
            <Switch>
              <Route exact path='/massage' component={Massage}></Route>
              <Route exact path='/nails' component={Nails}></Route>
              <Route exact path='/facials' component={Facials}></Route>
              <Route exact path='/lashBrow' component={LashBrow}></Route>
              <Route exact path='/contact' component={Contact}></Route>
              <Route exact path='/reiki' component={Reiki}></Route>
              <Route exact path='/prices' component={Prices}></Route>
              <Route exact path='/offers' component={Offers}></Route>
              <Route exact path='/' component={Home}></Route>
            </Switch>
            <Footer /> */}
            <UnderConstruction />
          </div>
        </section>
      </BrowserRouter>
    );
  }
}

export default App;
